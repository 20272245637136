<template>
    <div>
        <div :style="{ width: this.$oucy.pageWidth + 'px', display: 'inline-block' }">
            <el-row>
                <el-col :span="5">
                    <div class="left_nav">
                        <myMenu :list="allGlobalClassifyList1" :default-active="QueryFurnitureLibrary.cardCategory" @menuSelect="menuSelect1">
                            <span class="bold_span m-b-20" style="
                  padding-top: 30px;
                  padding-left: 20px;
                  display: inline-block;
                ">
                                企业名片
                            </span>
                        </myMenu>
                        <myMenu :list="allGlobalClassifyList0" :default-active="QueryFurnitureLibrary.cardCategory" @menuSelect="menuSelect">
                            <span class="bold_span m-b-20" style="
                  padding-top: 30px;
                  padding-left: 20px;
                  display: inline-block;
                ">
                                个人名片
                            </span>
                        </myMenu>
                    </div>
                </el-col>
                <el-col :span="19">
                    <div class="middle p-40 text-left">
                        <div class="c-2 f16 pointer text-right" @click="$oucy.back()">
                            &lt; 返回
                        </div>
                        <div class="df m-t-20">
                            <div>
                                <img :src="$oucy.ossUrl + detail.enterpriseAvata" class="enterpriseAvata" v-if="detail.enterpriseAvata" />
                            </div>
                            <div class="m-l-20">
                                <div class="c-1 f24 m-t-10">{{ detail.enterpriseName }}</div>
                                <div class="df c-2 m-t-25">
                                    <div class="m-r-70">
                                        法定代表人
                                        <span class="m-l-10 c-1">
                                            {{ detail.enterpriseLegalPerson }}
                                        </span>
                                    </div>
                                    <div class="m-r-70">
                                        注册资本
                                        <span class="m-l-10 c-1">
                                            {{ detail.enterpriseRegCapital }}
                                        </span>
                                    </div>
                                    <div class="m-r-70">
                                        成立日期
                                        <span class="m-l-10 c-1">
                                            {{ detail.enterpriseSetUpDate }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="df-jc-s-b text-center c-1 m-t-40">
                            <div class="box1">
                                <div class="f18 bold">{{ detail.cardPopularity }}</div>
                                <div class="f14 m-t-15">
                                    <i class="iconfont m-r-5">&#xe602;</i>
                                    <span class="c-2">人气</span>
                                </div>
                            </div>
                            <div class="box1 cu-p" @click="praiseChange(detail)">
                                <div class="f18 bold">{{ detail.cardLike }}</div>
                                <div class="f14 m-t-15">
                                    <!-- <i class="el-icon-star-on f20 m-r-5"></i> -->
                                    <i class="iconfont m-r-5" v-if="detail.like">&#xec8c;</i>
                                    <i class="iconfont m-r-5" v-else>&#xe600;</i>
                                    <span class="c-2">点赞</span>
                                </div>
                            </div>
                            <div class="box1 cu-p" @click="CollectionChange(detail)">
                                <div class="f18 bold">{{ detail.cardCollect }}</div>
                                <div class="f14 m-t-15">
                                    <i class="iconfont m-r-5" v-if="detail.collect">&#xe8c6;</i>
                                    <i class="iconfont m-r-5" v-else>&#xe601;</i>
                                    <span class="c-2">收藏</span>
                                </div>
                            </div>
                            <div class="box1 cu-p" @click="sharePoster()">
                                <div class="f18 bold">{{ detail.cardTransmit }}</div>
                                <div class="f14 m-t-15">
                                    <i class="iconfont m-r-5">&#xe627;</i>
                                    <span class="c-2">转发</span>
                                </div>
                            </div>
                        </div>
                        <div class="f16 c-1 m-t-50">
                            <p v-html="detail.enterpriseIntro" class="html"></p>
                        </div>
                        <div class="m-t-40 df m-b-30">
                            <div>
                                <img :src="$oucy.ossUrl + detail.cardCode" class="cardCode" v-if="detail.cardCode" />
                            </div>
                            <div class="c-2 f16 m-l-15">
                                <div>
                                    企业电话
                                    <span class="m-l-10 c-1">{{ detail.cardPhone }}</span>
                                </div>
                                <div class="m-t-15">
                                    企业地址
                                    <span class="m-l-10 c-1">{{ detail.enterpriseAddr }}</span>
                                </div>
                                <div class="m-t-15">
                                    企业品牌
                                    <span class="m-l-10 c-1" v-for="(v, i) of brands" :key="i" :title="v.brandDesc">
                                        {{ v.brandName }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-if="detail.enterpriseImage">
                            <div class="bold f20 c-1">企业视频/相册</div>
                            <MyMedia :srcList="$oucy.sortMp4Image(detail.enterpriseImage)" class="m-t-20" :size="173.7" />
                        </div>

                        <div v-if="ReturnsObject.content" class="m-t-30">
                            <div class="bold c-1 f16">员工名片</div>
                            <div class="m-t-20 box3s">
                                <div v-for="(item, index) in ReturnsObject.content" :key="index" @click="$oucy.go('/staffDetail?id=' + item.id + '&enterpriseId=' + id)" class="box3 df-ai-c">
                                    <div class="df-ai-c">
                                        <div>
                                            <img :src="$oucy.ossUrl + item.employeePic" class="employeePic" v-if="item.employeePic" />
                                        </div>
                                        <div class="m-l-10">
                                            <div class="c-1">
                                                {{ item.employeeName }}
                                                <span class="m-l-10 c-2 f14">
                                                    {{ item.employeeJob }}
                                                </span>
                                            </div>
                                            <div class="c-2 f14 m-t-10">{{ item.employeePhone }}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <el-button size="mini" type="primary">申请合作</el-button>
                                        </div>
                                        <div class="m-t-10">
                                            <el-button size="mini" type="primary">在线联系</el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pagination">
                        <el-pagination v-if="ReturnsObject.content" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="ReturnsObject.size" :total="ReturnsObject.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange"></el-pagination>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 分享海报 -->
        <sharePoster ref="sharePoster"></sharePoster>
    </div>
</template>
<script>
import {
    cardEmployee,
    enterpriseinfo,
    ltIdentity,
    china,
    cardCategory,
    cardPerson,
    cardEnterprise,
    furnitureGlobalBrand,
} from "@/service/index.js";
import { localSet, localGet, localDel } from "@/store/store";
import oucy from "@/util/oucyUtil";

export default {
    name: "Card",
    data() {
        return {
            type: 1,
            showcity: false,
            showpro: false,

            bannerHeight: 1000,
            // 企业分类
            allGlobalClassifyList1: [],
            // 个人分类
            allGlobalClassifyList0: [],
            // 请求对象
            QueryFurnitureLibrary: {
                limit: 10,
                start: 0,
                // enterpriseAuthenticationLevel: 0, //   认证级别：0全部 1实名认证 2信守约 3品质工厂
                cardPersonSort: 0, //  排序方式：0 销量[默认], 排序1 最新, 3 收藏, 4 人气,
                cardRegion: null, //    位置区域
                siteId: null, //    站点
                cardCategory: null, //名片分类
                employeeStatus: 1,
            },
            // 返回对象
            ReturnsObject: {},

            detail: {},
            id: null,
            brands: [],
        };
    },
    mounted() {
        this.id = this.$route.query.id;
        this.QueryFurnitureLibrary.employeeEnterprise = this.id;
        this.getCardEnterpriseDetail();
        this.getUserBrands();
        this.getCardCategoryList();
        this.getCardEmployeeList();
    },
    methods: {
        // 请求分类列表
        getCardCategoryList() {
            cardCategory.getCardCategoryList({ categoryType: 1 }).then((res) => {
                this.allGlobalClassifyList1 = res || [];
                this.allGlobalClassifyList1.unshift({
                    categoryName: "全部企业",
                    id: "全部企业",
                    categoryType: 1,
                    categoryIconPc: "1e3c102c68fb4377943dd3861d2b59f9.png",
                });
                this.$oucy.keyAs(this.allGlobalClassifyList1, "categoryName");
            });
            cardCategory.getCardCategoryList({ categoryType: 0 }).then((res) => {
                this.allGlobalClassifyList0 = res || [];
                this.allGlobalClassifyList0.unshift({
                    categoryName: "全部个人",
                    id: "全部个人",
                    categoryType: 0,
                    categoryIconPc: "47ead6bab0ff454ab85d4ab901a68ca3.png",
                });
                this.$oucy.keyAs(this.allGlobalClassifyList0, "categoryName");
            });
        },
        // 切换菜单回调
        menuSelect1(v) {
            this.type = 1;
            console.log(v);
            this.$oucy.go(`/card?type=${this.type}&cardCategory=${v}`);
            // this.QueryFurnitureLibrary.cardCategory = v
            // this.getCardEmployeeList()
        },
        menuSelect(v) {
            this.type = 0;
            console.log(v);
            this.$oucy.go(`/card?type=${this.type}&cardCategory=${v}`);
            // this.QueryFurnitureLibrary.cardCategory = v
            // this.getCardEmployeeList()
        },
        //点赞
        praiseChange(row) {
            if (row.like) {
                this.$message({
                    message: "您已对该企业点赞",
                    type: "warning",
                });
                return;
            }
            this.$oucy
                .postRequest("/client/card/cardenterprise/addCardEnterpriseLike", {
                    id: row.id,
                })
                .then((res) => {
                    row.like = true;
                    this.$message({
                        message: "点赞成功",
                        type: "success",
                    });
                });
        },
        //收藏
        CollectionChange(row) {
            if (row.collect) {
                this.$message({
                    message: "您已收藏该企业",
                    type: "warning",
                });
                return;
            }
            this.$oucy
                .postRequest("/client/card/cardenterprise/cardEnterpriseCollect", {
                    id: row.id,
                })
                .then((res) => {
                    row.collect = true;
                    this.$message({
                        message: "收藏成功",
                        type: "success",
                    });
                });
        },
        // 查询企业列表
        getCardPersonList() {
            if (this.type == 0) {
                cardPerson
                    .getCardPersonList(this.QueryFurnitureLibrary)
                    .then((res) => {
                        this.ReturnsObject = res || {};
                    });
            } else {
                cardEnterprise
                    .getCardEnterpriseList(this.QueryFurnitureLibrary)
                    .then((res) => {
                        this.ReturnsObject = res || {};
                    });
            }
        },

        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary.limit = pageSize;
            me.getCardEmployeeList();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.QueryFurnitureLibrary.start = current - 1;
            me.getCardEmployeeList();
        },
        findChina() {
            china.findChinaTree({ chinaId: null }).then((res) => {
                this.chinaList = res.children;
                /*                chinaList=Array.from(res).map(item => ({
                                          value: item.chinaId,
                                          label: item.chinaName,
                                          leaf: !item.hasChildren
                                      }));*/
            });
        },

        getCardEnterpriseDetail() {
            cardEnterprise.getCardEnterpriseDetail({ id: this.id }).then((res) => {
                this.detail = res || {};
                this.$oucy.setTitle(this.detail.enterpriseName);
            });
        },
        // 查询品牌
        getUserBrands() {
            furnitureGlobalBrand
                .getUserBrands({ enterpriseId: this.id })
                .then((res) => {
                    this.brands = res || [];
                });
        },
        // 查询员工
        getCardEmployeeList() {
            cardEmployee
                .getCardEmployeeList(this.QueryFurnitureLibrary)
                .then((res) => {
                    this.ReturnsObject = res || [];
                });
        },
        sharePoster(){
            let obj = {
                objectId: this.detail.enterpriseCardId,
                playbillCategory: 3,
                redirectUrlScene:{enterpriseId:this.id}
            };
            this.$refs.sharePoster.show(obj,()=>{
                this.addCardEnterpriseTransmit()
            })
        },
        addCardEnterpriseTransmit(){
            cardEnterprise.addCardEnterpriseTransmit({id:this.id}).then(res=>{
                ++this.detail.cardTransmit
            })
        },
    },
};
</script>
<style scoped lang="less">
.left_nav {
    margin-top: 20px;
}

.left_nav .el-menu {
    /*margin-left:20px;*/
    z-index: 1000;
}

.left_nav .el-button--text {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    margin: 30px 0 0 30px;
    color: #111111;
}

.left_nav .el-button--text:focus {
    color: #2090ff;
}

.el-menu {
    border-right: 0 !important;
    height: 900px;
}

.el-menu-item {
    font-size: 13px;
}

.nav img {
    margin-right: 8px;
}

.nav:hover .icon1 {
    display: none;
}

.nav:hover .icon2 {
    display: inline-block;
}

.icon2 {
    display: none;
}

.el-collapse {
    border: none;
}

.el-collapse-item__header {
    border: none;
}

.el-collapse-item__wrap {
    margin-top: 20px;
}

.top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
}

.place .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
    margin-bottom: 10px;
}

.place {
    margin-bottom: 20px;
    margin-left: -170px;
}

.place>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
}

.auth {
    margin-bottom: 30px;
    display: flex;
}

.auth>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.auth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #f6f7fb;
    margin-left: 10px;
    margin-top: 10px;
    text-align: left;
}

.top_sort .el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
}

.sort /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #f6f7fb;
    margin-left: 10px;
}

.sort {
    margin-bottom: 30px;
    display: flex;
}

.sort>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.el-radio-button--text:hover {
    color: rgb(32, 144, 255) !important;
}

.el-radio-button--mini {
    border: none;
}

.el-radio-button--mini:hover {
    background-color: rgb(32, 144, 255) !important;
}

.el-radio-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
}

/deep/ .el-collapse-item__wrap {
    margin-top: 20px;
    text-align: center;
}

.middle ul li:nth-child(3n) {
    margin-right: 0px;
}

.middle ul li {
    width: 252px;
    height: 280px;
    background-color: #ffffff;
    padding: 24px;
    display: inline-block;
    /*float: left;*/
    margin: 10px;
    list-style: none;
}

.personal_card ul li {
    height: 250px;
}

.enterprise_card {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 10px;
}

.enterprise_card span {
    width: 160px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    float: left;
    display: flex;
    padding: 25px 0 0 10px;
}

.com_head {
    width: 80px;
    height: 80px;
    border: 1px rgb(243, 243, 243) solid;
    float: left;
}

.com_head img {
    margin: 15px auto;
    width: 100%;
    height: 100%;
}

.enterprise_card .info {
    width: 80px;
    /*height: 43px;*/
    background: #f8f8f9;
    border-radius: 2px;
    /*margin-top: 30px;*/
    margin-left: 6px;
}

.enterprise_card .info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    /*margin-top: -20px;*/
}

.enterprise_card .info p+p {
    /*padding-top: 10px;*/
}

.personal_card .info {
    width: 57px;

    background: #f8f8f9;
    border-radius: 2px;
}

.personal_card .info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
    line-height: 20px;
    text-align: center;
    /*margin-top: -20px;*/
}

.staff_info {
    width: 88px;
}

.staff_info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    text-align: center;
}

.contact {
    width: 233px;
    /*height: 50px;*/
    background-color: rgb(243, 249, 255);
    margin: 6px auto;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact .el-button--text {
    width: 70px;
    border: 1px rgb(32, 144, 255) solid;
    float: right;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
}

.pagination {
    margin: 30px auto 120px;
}

/*/deep/ .btn-next {
    width: 128px;
    height: 46px !important;
    background: #FFFFFF !important;
}

/deep/ .btn-prev {
    width: 128px;
    height: 46px !important;
    background: #FFFFFF !important;
}

/deep/ .el-pager {
    position: relative;
    top: 9px;
}

/deep/ .number {
    background: #FFFFFF !important;
}

/deep/ .active {
    background: #2090ff !important;
}*/

.bus_recomm {
    width: 100%;
}

.left_nav {
    margin-top: 20px;
}

.left_nav .el-menu {
    /*margin-left:20px;*/
    z-index: 1000;
}

.el-menu {
    border-right: 0 !important;
    height: 900px;
}

.el-menu-item {
    font-size: 13px;
}

.nav img {
    margin-right: 8px;
}

.nav:hover .icon1 {
    display: none;
}

.nav:hover .icon2 {
    display: inline-block;
}

.icon2 {
    display: none;
}

.bold_span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    /*margin: 30px 0 0 30px;*/
}

.top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
}

.place .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
    margin-bottom: 10px;
}

.place {
    text-align: left;
    margin-bottom: 20px;
    /*margin-left: -170px;*/
    padding-left: 10px;
}

.place>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
}

.auth {
    margin-bottom: 30px;
    display: flex;
}

.auth>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.auth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #f6f7fb;
    margin-left: 10px;
    /*margin-top: 10px;*/
    text-align: left;
}

.el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
}

.sort /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #f6f7fb;
    margin-left: 10px;
}

.sort {
    margin-bottom: 30px;
    display: flex;
}

.sort>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.el-radio-button--text:hover {
    color: rgb(32, 144, 255) !important;
}

.el-radio-button--mini {
    border: none;
}

.el-radio-button--mini:hover {
    background-color: rgb(32, 144, 255) !important;
}

.el-radio-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
}

/deep/ .el-collapse-item__wrap {
    margin-top: 20px;
    text-align: center;
}

.com_head {
    width: 80px;
    height: 80px;
    border: 1px rgb(243, 243, 243) solid;
    float: left;
}

.com_head img {
    width: 73px;
    height: 47px;
    margin-top: 15px;
}

.middle_card_star {
    float: left;
    display: flex;
    margin-left: 10px;
}

.info {
    width: 70px;
    /*height: 43px;*/
    background-color: rgb(248, 248, 249);
    padding: 2px;
    margin: 15px auto 0px;
}

.info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 6px;
}

.goods_info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    text-align: left;
    margin-left: 7px;
}

.pagination {
    margin-top: 50px;
    line-height: 150px;
}

.middle ul li:nth-child(3n) {
    margin-right: 0px;
}

.items {
    margin-left: 10px;
}

.item {
    padding: 20px;
    background: #fff;
    margin-top: 10px;
    display: flex;
}

.logo {
    width: 168px;
    height: 168px;
    background: #fdfdfd;
    border: 1px solid #f3f3f3;
    border-radius: 1px;
}

.dot {
    padding-left: 30px;
    flex: 1;
}

.itemTop {
    align-items: flex-start;
}

.icon3 {}

.icon4 {
    margin-right: 5px;
}

.cover {
    width: 80px;
    margin-left: 10px;
}

.myauth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #f6f7fb;
    /*margin-left: 10px;*/
    /*margin-top: 10px;*/
    text-align: left;
}

.mybutton {
    margin: 0 10px 10px 0;
    background: #f6f7fb;
    border: none;
    color: #606266;
    padding: 6px 12px;
    border-radius: 4px;
}

.text-left {
    text-align: left;
}

.myauth {
    display: flex;
    text-align: left;
}

.myauth .myauthLift {
    font-weight: bold;
    display: block;
    width: 90px;
}

.myauth .text-left {
    flex: 1;
}

.selectedChina {
    color: #fff;
    background-color: rgb(32, 144, 255);
}

.el-radio-group {
    text-align: left;
}

.middle {
    background: #fff;
    margin-top: 20px;
    margin-left: 20px;
}

.enterpriseAvata {
    width: 90px;
    height: 90px;
}

.box1 {
    margin-right: 20px;
    padding: 20px;
    flex: 1;
    background: #f8f8f9;
}

.cardCode {
    width: 120px;
    height: 120px;
}

.box2s {
    display: flex;
    flex-wrap: wrap;
}

.box2 {
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px;
    box-sizing: border-box;
    background: #fafafa;
    background-color: #ffffff;
    padding-bottom: 15px;
    border: 1px solid #eaeaea;
    text-align: center;
    width: 218px;

    &:hover {
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
        background: #ffffff;
    }

    .box img {
        width: 100%;
        // height: 163px;
    }
}

.box3s {
    display: flex;
    flex-wrap: wrap;
}

.box3 {
    display: flex;
    justify-content: space-between;
    border: 1px solid #eaeaea;
    width: 410px;
    padding: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.employeePic {
    width: 80px;
    height: 80px;
}

.html /deep/ img {
    max-width: 100%;
}
</style>